@keyframes rotate {
    from {
        rotate: 0deg;
    }

    50% {
        scale: 1 1.25;
    }

    to {
        rotate: 360deg;
    }
}

.app__blob {
    overflow: hidden;
}

#blob {
    background-color: white;
    animation: rotate 20s infinite;
    background: linear-gradient(
        to right,
        rgba(157, 255, 212, 0.5),
        rgba(147, 112, 216, 0.5),
    );

    height: 650px;
    aspect-ratio: 1;
    position: fixed;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    z-index: 1;
    border-radius: 50%;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
        height: 550px;
    }

    @media screen and (max-width: 800px) {
        height: 68vw;
    }

    @media screen and (max-width: 500px) {
        height: 75vw;
    }
}

#blur {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2;
    backdrop-filter: blur(200px);
}