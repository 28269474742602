@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "Roboto", sans-serif;

  --primary-color: #9e9e9e;
  --secondary-color: #303030;
  --yellow-color: rgb(238, 255, 0);
  --peach-color: #EEA59D;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --offBlackGlass-color: rgba(14, 14, 14, 0.75);
  --gray-color: rgb(127,127,127);
  --grayGlass-color: rgba(127,127,127, 0.75);  
  --midGrey-color: rgb(65,65,65);
  --midGreyGlass-color: rgba(65,65,65, 0.75);
  --darkGray-color: rgb(20,20,20);
  --darkGrey-color: rgb(20,20,20);
  --offBlack-color: rgba(10,10,10, 0.98);
  --brown-color: #46364a;
  --white-color: #ffffff;
  --black-color: rgb(0, 0, 0);
  --offWhite-color: #dddddd;  
  --offWhiteGlass-color: rgba(230, 230, 230, 0.75);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
