// Header.scss
#home {
    position: relative;
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    ///background-color: var(--darkGray-color);
    
    // Disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
}
  
  body.active-modal {
    overflow-y: hidden;
  }
  
  .app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 10;

    padding: 2rem;
    padding-top: 4.2rem;    
    padding-bottom: 2.5rem;
    margin-bottom: 0.5rem;

    @media screen and (max-width: 1050px) {
        padding-top: 4rem;
        padding-bottom: 2rem;
    }

    @media screen and (max-width: 650px) {
        padding-top: 3.5rem; 
        padding-bottom: 1.5rem;
    }

    @media screen and (max-width: 450px) {
        padding-top: 4rem;    
        padding-bottom: 1rem;
    }

    @media screen and (max-width: 350px) {     
        padding-top: 3.25rem; 
        padding-bottom: 0.5rem;
    }

    @media screen and (max-width: 320px) {
        padding-top: 3.4rem;
    }
    .app__header-content {
        overflow: hidden;

        @media screen and (max-width: 500px) {
            align-items: center;
            display: flex;        
            flex-direction: column;
        }

        .logo_body{
            display: grid;
            place-items: center;
            margin: 0;
            overflow: hidden;
            width: 100vw;
        
            .logos{
                position: relative;
                user-select: none;
        
                h1 {
                    position: relative;
                    font-size: 5.5rem; // Adjust the font size as needed                        
                    color: var(--white-color);
                    z-index: 5;
                    white-space: nowrap; // Add this line
            
                    &:nth-child(2) {
                      top: 0rem; // Adjust this value to position the second h1
                      color: var(--darkGray-color);   
                      color: rgba(20, 20, 20, 0.45);
                      position: absolute;
                      z-index: 4;
                      filter: blur(2.5px); // Adjust the blur value as needed
                    }
            
                    &:nth-child(3) {
                      top: 0rem; // Adjust this value to position the third h1
                      color: rgba(20, 20, 20, 0.35);
                      position: absolute;
                      z-index: 3;
                      filter: blur(2px); // Adjust the blur value as needed
                    }
            
                    &:nth-child(4) {
                      top: 0rem; // Adjust this value to position the fourth h1
                      color: rgba(20, 20, 20, 0.2);
                      position: absolute;
                      z-index: 2;
                      filter: blur(2px); // Adjust the blur value as needed
                    }
            
                    &:nth-child(5) {
                      top: 0rem; // Adjust this value to position the fifth h1
                      color: rgba(20, 20, 20, 0.1);
                      position: absolute;
                      z-index: 1;
                      filter: blur(2px); // Adjust the blur value as needed
                    }

                    &:nth-child(6) {
                        top: 0rem; // Adjust this value to position the fifth h1
                        color: rgba(20, 20, 20, 0.05);
                        position: absolute;
                        z-index: 1;
                        filter: blur(2px); // Adjust the blur value as needed
                      }
            
                    @media screen and (max-width: 720px) {
                      font-size: 5.0rem;
                    }

                    @media screen and (max-width: 650px) {
                        font-size: 4.5rem;
                    }
                
                    @media screen and (max-width: 580px) {
                        font-size: 4rem;
                    }
                
                    @media screen and (max-width: 500px) {
                        font-size: 3.5rem;
                    }
        
                    @media screen and (max-width: 450px) {
                        font-size: 3rem;
                    }
                
                    @media screen and (max-width: 400px) {
                        font-size: 2.75rem;
                    }
        
                    @media screen and (max-width: 350px) {
                        font-size: 2.5rem;
                    }
        
                    @media screen and (max-width: 300px) {
                        font-size: 2.25rem;
                    }
            
                    &:hover {
                      cursor: default;
                    }
                }
            }
        }

        h1 {
            font-size: 5.5rem;
            color: var(--white-color);
    
            @media screen and (max-width: 720px) {
                font-size: 5.0rem;
            }
        
            @media screen and (max-width: 650px) {
                font-size: 4.5rem;
            }
        
            @media screen and (max-width: 580px) {
                font-size: 4rem;
            }
        
            @media screen and (max-width: 500px) {
                font-size: 3.5rem;
            }

            @media screen and (max-width: 450px) {
                font-size: 3rem;
            }
        
            @media screen and (max-width: 400px) {
                font-size: 2.75rem;
            }

            @media screen and (max-width: 350px) {
                font-size: 2.5rem;
            }

            @media screen and (max-width: 300px) {
                font-size: 2.25rem;
            }
        }

        .app__header-subtitles {
            display: flex;
            justify-content: space-around;
            justify-content: center;
            margin-top: 1rem;
            overflow: hidden;

            @media screen and (max-width: 500px) {
                margin-top: 0.85rem;
            }
            @media screen and (max-width: 450px) {
                margin-top: 0.65rem;
            }
            @media screen and (max-width: 400px) {
                margin-top: 0.5rem;
            }
            @media screen and (max-width: 300px) {
                margin-top: 0.35rem;
            }
    
            h2 {
                font-size: 1.75rem;
                font-family: var(--font-base);
                font-family: "Roboto", monospace;
                text-transform: uppercase;
                color: var(--offWhite-color);
                text-shadow: 0.25px 0.5px 2px rgba(0, 0, 0, 0.4);
                transition: all 0.2s ease-in-out;                
                padding-left: 2rem;
                padding-right: 2rem;

                @media screen and (max-width: 720px) {
                    font-size: 1.7rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            
                @media screen and (max-width: 580px) {
                    font-size: 1.5rem;
                    padding-left: 0.7rem;
                    padding-right: 0.75rem;
                }
            
                @media screen and (max-width: 500px) {
                    font-size: 1.35rem;
                    padding-left: 0.6rem;                    
                    padding-right: 0.6rem;
                }
    
                @media screen and (max-width: 450px) {
                    font-size: 1.15rem;
                    padding-left: 0.55rem;                    
                    padding-right: 0.6rem;
                }
            
                @media screen and (max-width: 400px) {
                    font-size: 1.025rem;
                }
    
                @media screen and (max-width: 350px) {
                    font-size: 0.95rem;
                    padding-left: 0.5rem;                    
                    padding-right: 0.5rem;
                }
    
                @media screen and (max-width: 300px) {
                    font-size: 0.94rem;
                    padding-left: 0.35rem;                    
                    padding-right: 0.35rem;
                }
    
                &:hover{
                    cursor: none;
                    cursor: crosshair;
                    color: rgb(12, 73, 40);
                    text-shadow: 0 0 3px rgba(12, 73, 40, 0.6); /* Adjust values as needed */                    
                }
            }
        }
    }
}