.app__privacy {
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--darkGrey-color);
  padding: 0.3rem 0rem 0rem;
  position: relative;

  .privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
    padding-top: 3.8rem;
    font-family: 'Arial', sans-serif;
    background-color: var(--darkGrey-color);

    h1 {
      color: var(--white-color);
      margin-bottom: 1rem;
    }

    h2 {
      color: var(--white-color);
      padding-top: 1rem;
    }

    p {
      color: var(--white-color);
      margin-bottom: 0.5rem;
    }

    ul {
      list-style-type: disc;
      margin-left: 20px;
      color: var(--white-color);
      margin-bottom: 1rem;
    }
  }
}
